import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from "docz";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "code-challenge"
    }}>{`Code Challenge`}</h1>
    <h2>
  We ask you to develop a hypothetical homepage for a Styling and Photography
  collective in this code challenge.
    </h2>
    <p><img alt="./screen.png" src={require("./screen.jpg")} /></p>
    <hr></hr>
    <h2 {...{
      "id": "links"
    }}>{`Links`}</h2>
    <ul>
      <li parentName="ul">{`Design: `}<a parentName="li" {...{
          "href": "https://www.figma.com/file/vQw32zU0ZnFEYXkAcsSekC/wild-dev-challenge"
        }}>{`https://www.figma.com/file/vQw32zU0ZnFEYXkAcsSekC/wild-dev-challenge`}</a></li>
      <li parentName="ul">{`Images and fonts: `}<a parentName="li" {...{
          "href": "/public/wild-code-challenge.zip"
        }}>{`https://docs.wild.plus/public/wild-code-challenge.zip`}</a></li>
    </ul>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`The homepage includes an image gallery, with a couple of additional details, like the author, the date, and a link.
When the user scrolls or clicks on the prev/next images, the slider will change content.
The cursor will show the progress through a circle indicator.`}</p>
    <p>{`Regarding the font sizes and the element proportions, try to stay as close as possible to the Figma design.`}</p>
    <h2 {...{
      "id": "evaluations-points"
    }}>{`Evaluations points`}</h2>
    <p>{`You will be evaluated on:`}</p>
    <ul>
      <li parentName="ul">{`Pixel-perfect level`}</li>
      <li parentName="ul">{`Code readability`}</li>
      <li parentName="ul">{`Taste in animations and interactions`}</li>
      <li parentName="ul">{`Animations fluidity`}</li>
      <li parentName="ul">{`Good separation of concerns`}</li>
    </ul>
    <h2 {...{
      "id": "suggested-stack"
    }}>{`Suggested stack`}</h2>
    <ul>
      <li parentName="ul">{`React 17+`}</li>
      <li parentName="ul">{`Styled Components`}</li>
      <li parentName="ul">{`Typescript`}</li>
      <li parentName="ul">{`Framer Motion or GSAP`}</li>
    </ul>
    <h2 {...{
      "id": "expected-output"
    }}>{`Expected output`}</h2>
    <p>{`We expect to receive a link to a Github repo (or eventually a zip file).`}<br parentName="p"></br>{`
`}{`If it is possible, we would appreciate an online demo area.`}</p>
    <br />
    <br />
    <hr></hr>
    <h2>
  Thanks for your time!
  <br />
  We're looking forward to see what you're capable of!
    </h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      